import logo from './logo.svg';
import './App.css';

function App() {
  document.title = 'Golifee Affection'
  return (
    <div className='w-full min-h-screen bg relative flex justify-center items-center overflow-hidden'>
      <img src="/img/icon.png" alt="" className='hidden tiny:block absolute w-52 -z-10 -translate-y-[30vh]' />

      <img src="/img/decoration3.png" alt="" className='absolute w-20 md:w-28 -left-4' />
      <img src="/img/decoration3.png" alt="" className='absolute w-20 md:w-28 -right-4' />
      <img src="/img/decoration2.png" alt="" className='absolute w-32 md:w-40 top-0 -right-14' />
      <img src="/img/decoration1.png" alt="" className='absolute w-32 md:w-60 -bottom-14 md:-bottom-24 left-[10vw]' />
      <img src="/img/gift.png" alt="" className='absolute w-32 md:w-60 -bottom-7 md:-bottom-14 right-[10vw]' />
      <img src="/img/gift.png" alt="" className='absolute w-32 md:w-48 top-4 -left-14 rotate-[27deg]' />

      <div className='flex-col flex items-center justify-center gap-7 px-20 md:px-24 '>
        <h1 className='font-[Candal] text-4xl md:text-6xl text-[#4d5a7b]'>GLOFIEE</h1>
        <p className='text-[#152938] tracking-[5px] text-lg md:text-4xl text-center'>Gifts of Love For Everyone, Everywhere!</p>
        <a target='_blank' href='http://linktr.ee/glofiee' className='px-7 py-2 bg-[#3c4661] text-sm md:text-base text-white rounded-full font-[OpenSauce] font-bold tracking-widest text-center'>CREATE TEMPLATE!</a>
      </div>
    </div>
  );
}

export default App;
